<template>
    <b-card>
        <Form
            ref="createActor"
            :actor="actor"
            :submit="submit"
        />
    </b-card>
</template>

<script>
import Form from "./partials/Form";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "EditActor",

    components: {
        Form
    },

    data() {
        return {
            id: this.$route.params.id
        }
    },

    computed: {
        ...mapGetters('actor', [
            'actorById'
        ]),
        actor() {
            let actor = this.actorById(this.id)
            if (!actor) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.$router.push({ name: 'actors' })

                return
            }
            actor.meta_keywords = actor.meta_keywords ? actor.meta_keywords.split(',') : []

            return actor
        }
    },

    methods: {
        ...mapActions({
            editActor: 'actor/edit',
        }),
        submit() {
            const refActor = this.$refs.createActor
            refActor.errMessage = null

            const data = JSON.parse(JSON.stringify(refActor.actor))
            data.meta_keywords = data.meta_keywords.toString()
            this.editActor(data).then(() => {
                this.$router.push({ name: 'actors' })
            }).catch(response => {
                if (response.errors) {
                    refActor.$refs.form.setErrors(response.errors)
                }
                refActor.errMessage = response.message
            })

        }
    }

}
</script>
